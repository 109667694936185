.attrImage {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #000;
}
.attributes a {
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.attributes a:hover,
.attributes a:hover:after {
  color: blueviolet;
}
.author {
  position: relative;
}
.author a::after {
  content: "↗";
  position: absolute;
  display: grid;
  place-content: center;
  bottom: 50%;
  left: 105%;
  font-size: 0.4rem;
  border: 1px solid #333;
  width: 1.3em;
  height: 1.3em;
}
