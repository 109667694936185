.ImageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.ImageContainer * {
  border-radius: 1em;
}

.Image {
  width: 100%;
  height: 100%;
  border: 10px solid #0000;
  aspect-ratio: 1;
  transition: all 0.3s;
  -webkit-user-drag: none;
  user-select: none;
  cursor: cell;
}

.Dropdown {
  position: absolute;
  display: flex;
  gap: 0.5em;
  place-content: center;
  translate: 100%;
  transform-origin: left top;
  scale: 0;
  right: 0;
  top: 0%;
  width: max-content;
  min-width: 5em;
  z-index: 1;
  background-color: aliceblue;
  padding: 1em;
  margin: 0;
}

.DropdownLeft {
  left: 0;
  translate: -100%;
  transform-origin: right top;
}

.Tier {
  padding: 0.5em 1em;
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-weight: bold;
  box-shadow: 1px 1px 5px;
  font-size: 1.2em;
  user-select: none;
  transition: 0.2s;
}

.Selected {
  opacity: 0.5;
  filter: saturate(70%);
}

.Description {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 920px) {
  .ImageContainer {
    position: initial;
  }
  .Dropdown {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    translate: 0;
    transform-origin: center center;
    height: max-content;
    width: max-content;
    overflow-y: auto;
    inset: 0%;
  }
}
