.Form {
  position: fixed;
  display: grid;
  place-content: center;
  width: 100vw;
  height: 100vh;
  background: #0009;
  z-index: 100;
}

.Inputs {
  position: relative;
  display: grid;
  gap: 1em;
  border-radius: 2em;
  background: rgba(202, 202, 202, 0.9);
  overflow: hidden;
}

.Inputs input {
  padding: 0.8em;
  font-size: 1em;
  border-radius: 5px;
  border: none;
  min-width: 300px;
  width: 20vw;
}
.Input {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bolder;
  font-size: 1.1em;
  padding-inline: 2em;
}

.Tabs {
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;

  border-bottom: 1px solid white;
}

.Tab {
  width: 100%;
  margin: 0;
  padding: 1em;
  cursor: pointer;
}
.Tab:first-child {
  border-right: 1px solid white;
}

.Tab:hover {
  background-color: #ea88aa22;
}

.Selected {
  text-decoration: underline;
  background: #ea88aa66;
}

.Tab.Selected:hover {
  background: #ea88aa66;
}

.SubmitButton {
  padding: 1em;
  border: 0.2em solid #3a73;
  background: rgb(200, 243, 200);
  border-radius: 0.4em;
  cursor: pointer;
}

.SubmitButton:hover {
  background: rgb(177, 238, 177);
}
.SubmitButton:active {
  background: #3a7;
}

.Anonymous {
  padding-block-end: 2em;
}
