body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#eee, #fff) cover;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  display: flex;
  justify-content: flex-end;
  max-width: 1600px;
  width: 100vw;
  margin: auto;
  align-items: center;
  height: 100px;
  font-size: 1.2em;
}
footer a,
.buttonLink {
  color: black;
  font-weight: bold;
  border: 2px solid rgb(243, 106, 151);
  padding: 0.5em 1em;
  background-color: #f9b;
}

footer a:hover,
.buttonLink:hover {
  color: #222;
  background: #ea88aa;
}
.App {
  display: grid;
  grid-template-rows: max-content auto max-content;
  gap: min(5em, 3vh);
}
header {
  position: relative;
  background-color: #ea88aa;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5em;
}
main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.container {
  width: 100%;
  max-width: calc(1600px + 2em);
  margin: auto;
  padding: 2em;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hidden {
  display: none;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-size: 3em;
  font-weight: bold;
}

.signout button {
  border: none;
  width: 7vw;
  min-width: max-content;
  font-size: 1.07em;
  font-weight: bold;
  color: white;
  background: #f9b;
  border-left: 1px solid #ddd;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  transition: 0.18s;
}

.signout button:hover {
  background: rgb(255, 124, 168);
}

.images {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2em;
  padding-block: 2em;
  border-bottom: 3px solid #eee;
  height: 100%;
}
.images > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggled {
  opacity: 0.4;
  cursor: url("/public/images/materials/cursor-minus.cur"), pointer;
}

.link {
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  text-decoration: underline;
}
.link:hover {
  opacity: 0.8;
}

.loadingToggle::before,
.loadingToggle::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 1em;
}

.loadingToggle::before {
  background: #0004;
}

.loadingToggle::after {
  background: url("/public/images/materials/both_loggo.png");
  background-repeat: no-repeat;
  background-position: center;
  animation: rotate 0.7s alternate infinite;
}

#hide-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.checkbox {
  display: inline-block;
  height: 1em;
  width: 1em;
  border-radius: 0.2em;
  background-color: white;
}

.checked {
  position: relative;
  background-color: rgb(89, 255, 249);
}
.checked::after,
.checked::before {
  content: "";
  position: absolute;
  display: grid;
  place-content: center;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 2px;
  transform-origin: center center;
  background-color: black;
}
.checked::after {
  rotate: 45deg;
}
.checked::before {
  rotate: 135deg;
}
@keyframes rotate {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}

.fade,
.fade-enter,
.fade-enter-active,
.fade-exit-active {
  transition: 0.5s;
}

.fade-enter,
.fade-exit-active {
  opacity: 0;
  scale: 0 0.7;
}
.fade,
.fade-enter-active {
  opacity: 1;
  scale: 1;
}

.grow,
.grow-enter,
.grow-enter-done,
.grow-enter-active,
.grow-exit,
.grow-exit-active {
  transition: 0.5s;
}

.grow-exit,
.grow-exit-active {
  scale: 0;
}
.grow,
.grow-enter-done,
.grow-enter-active {
  scale: 1;
}
@media screen and (max-width: 1600px) {
  .images {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .signout button {
    width: 50vw;
    height: 100%;
    margin: 0;
    padding: 0;
    border-top: 1px solid #ddd;
  }

  .container {
    flex-direction: column-reverse;
  }
  header {
    display: block;
    padding: 0;
    margin: 0;
  }
}
@media screen and (max-width: 840px) {
  .images {
    grid-template-columns: repeat(3, 1fr);
    padding: 1em;
  }
}

@media screen and (max-width: 450px) {
  .container {
    align-items: flex-start;
  }
  .title {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 300px) {
  .container {
    align-items: flex-start;
  }
  .title {
    font-size: 1.5em;
  }
  .images {
    grid-template-columns: repeat(2, 1fr);
  }
}
